import { useState, useMemo, useCallback } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';

const validateEmail = (email) => /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/.test(email);

const joinClassNames = (...classes) => {
  return classes.join(' ');
};

const SubscriptionForm = () => {
  const [email, onChangeEmail] = useState('');

  const isValidEmail = useMemo(() => {
    if (email) return validateEmail(email);

    return true;
  }, [email]);

  const subscribe = useCallback(() => {
    if (email && isValidEmail) {
      axios.post('https://app.magio.io/api/subscribe', { email })
        .finally(() => {
          toast['success']('Thanks for subscribing!');
        })
    }
  }, [isValidEmail, email])

  return (
    <div className="flex-wrap w-full mb-10 px-4 py-4 bg-custom-light-blue rounded-[20px] flex items-center sm:mb-16 md:px-10 md:py-9  md:justify-center lg:flex-nowrap lg:justify-between">
      <p className="w-full text-base text-custom-dark font-normal mb-4 text-center sm:mr-5 lg:text-left lg:mb-0 md:text-[18px] md:leading-[25px] xl:whitespace-nowrap">
        Receive all our latest updates and news straight to your inbox!
      </p>
      <div className='flex max-[1200px]:w-full flex-wrap min-[500px]:flex-nowrap max-[600px]:w-full'>
        <input
          id="email"
          type="email"
          className="w-full h-[53px] max-[500px]:min-w-[260px] rounded-[100px] border-[1px] border-custom-grey px-5 min-[500px]:mr-4 max-[500px]:mb-4 lg:w-[304px]"
          placeholder="Your email"
          value={email}
          onChange={({ target: { value } }) => onChangeEmail(value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              subscribe()
            }
          }}  
        />
        <button
          className={joinClassNames(
            "max-[500px]:w-full h-[53px] rounded-[100px] bg-custom-primary-blue text-base text-white px-6 py-[14px]",
            "hover:bg-custom-dark-blue",
            !isValidEmail ? "opacity-50 cursor-not-allowed" : "",
          )}
          onClick={() => subscribe()}
        >
          Subscribe
        </button>
      </div>
    </div>
  );
}

export default SubscriptionForm;
