import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MagioInfo from './components/MagioInfo/MagioInfo';
import Header from './components/Header/Header';
import SubscriptionForm from './components/SubscriptionForm/SubscriptionForm';

const App = () => {
  return (
    <>
      <ToastContainer autoClose={1500} />
      <div className="px-4 sm:px-8 min-[400px]:px-6 md:px-20 md:h-screen lg:px-30 2xl:px-40 flex flex-col justify-between w-full overflow-x-hidden">
        <Header />
        <MagioInfo />
        <SubscriptionForm />
      </div>
    </>
  );
}

export default App;
