
import Lottie from "react-lottie";
import * as animation from "../../assets/Animation.json"

const MagioInfo = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="flex flex-col-reverse md:flex-row justify-between items-center">
      <div>
        <h2 className="text-2xl mt-4 mb-6 md:mt-[84px] md:mb-[40px] md:text-[30px] md:w-[100%] md:leading-[36px] max-[900px]:w-full lg:text-[44px] lg:leading-[50px] xl:w-[760px] xl:leading-[88px] xl:text-[80px] text-custom-dark font-medium w-[400px]">
          From natural language to BI insights.
        </h2>
        <p className="text-lg mb-8 md:mb-[60px] md:max-w-[336px] lg:max-w-[450px] md:w-[100%] xl:max-w-[609px] leading-[28px] xl:text-[24px] xl:leading-[34px] text-custom-dark font-normal">
          Our cutting-edge solutions are currently in a limited-access mode, but we'd love to share more with you, please contact us at
          {' '}
          <a href="mailto:Info@magio.io" className="text-custom-primary-blue mailbox">Info@magio.io</a>
        </p>
      </div>
      <div className="max-[425px]:w-[90%] max-[425px]:h-[90%] max-[640px]:w-[330px] max-[640px]:h-[330px] md:min-w-[290px] md:min-h-[290px] xl:w-[400px] xl:h-[400px] min-[1400px]:w-[460px] min-[1400px]:h-[460px]">
        <Lottie options={defaultOptions} />
      </div>
    </div>
  )
}

export default MagioInfo;
